import axios from 'axios';
import ky, { KyResponse } from 'ky';
import throttle from 'lodash/throttle';

import { userManager } from '@/manager/user.manager.ts';

import { errorEmitter } from '../../event/error.emitter.ts';
import { printPdf } from '../printPDF.ts';
import { waitOnline } from '../wait-online/wait-online.ts';
import { HTTPMethod } from './http.enum.ts';
import { HTTPError } from './http.error.ts';
import { HTTPRequest } from './http-request.ts';

export const isWeb = typeof navigator !== 'undefined';
export const isSafari = isWeb && /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
export const isFirefox = isWeb && /Firefox/i.test(navigator.userAgent);

type PostOptions = {
  query?: object;
};

const showError = throttle((status: number | null) => {
  if (status !== null) {
    errorEmitter.emit('snack', status);

    if (status === 401) {
      userManager.logout();
    }
  } else {
    // No Response
    errorEmitter.emit('snack', 444);
  }
}, 2000);

export class HTTP {
  private silent: boolean = false;

  constructor(silent?: boolean) {
    this.silent = silent || false;
  }

  private async send(request: HTTPRequest) {
    try {
      await waitOnline();
      const res = await ky(request.getUrl(), {
        ...request.getOptions(),
        retry: 3,
      });

      const { status } = res;
      if (!this.silent && (!res.ok || status === null || status >= 400)) {
        throw new HTTPError(res.statusText || 'Unknown error', res.status || 444);
      }

      return res;
    } catch (err: any) {
      if (err instanceof HTTPError) {
        if (!this.silent) {
          showError(err.status);
        }
        throw err;
      }

      if (!this.silent) {
        showError(err?.response?.status || 444);
      }
      throw new HTTPError(
        err?.response?.statusText || 'Unknown error',
        err?.response?.status || 444,
      );
    }
  }

  private isJson(res: KyResponse<unknown>): boolean {
    return !!res.headers.get('Content-Type')?.includes('application/json');
  }

  async get<T>(path: string, query?: object): Promise<T> {
    const request = new HTTPRequest();
    request.path = path;
    request.query = query || null;
    request.method = HTTPMethod.GET;

    const res = await this.send(request);
    if (this.isJson(res)) {
      return (await res.json()) as T;
    }
    return null as T;
  }

  async post<T>(path: string, body?: object, postOptions?: PostOptions): Promise<T> {
    const request = new HTTPRequest();
    request.path = path;
    request.query = postOptions?.query || null;
    request.body = body || null;
    request.method = HTTPMethod.POST;

    const res = await this.send(request);
    if (this.isJson(res)) {
      return (await res?.json()) as T;
    }

    return null as T;
  }

  async put<T>(path: string, body?: object, postOptions?: PostOptions): Promise<T> {
    const request = new HTTPRequest();
    request.path = path;
    request.query = postOptions?.query || null;
    request.body = body || null;
    request.method = HTTPMethod.PUT;

    const res = await this.send(request);
    if (this.isJson(res)) {
      return (await res?.json()) as T;
    }

    return null as T;
  }

  async patch<T>(path: string, body?: object, postOptions?: PostOptions): Promise<T> {
    const request = new HTTPRequest();
    request.path = path;
    request.query = postOptions?.query || null;
    request.body = body || null;
    request.method = HTTPMethod.PATCH;

    const res = await this.send(request);
    if (this.isJson(res)) {
      return (await res?.json()) as T;
    }

    return null as T;
  }

  async delete<T>(path: string, body?: object, postOptions?: PostOptions): Promise<T> {
    const request = new HTTPRequest();
    request.path = path;
    request.query = postOptions?.query || null;
    request.body = body || null;
    request.method = HTTPMethod.DELETE;

    const res = await this.send(request);
    if (this.isJson(res)) {
      return (await res?.json()) as T;
    }

    return null as T;
  }

  async download(path: string, filename: string, query?: object) {
    const request = new HTTPRequest();
    request.path = path;
    request.query = query || null;
    request.method = HTTPMethod.GET;

    const res = await this.send(request);
    const data = await res.blob();
    await this.downloadBlob(data, filename);
  }

  async downloadPost(path: string, filename: string, body?: object) {
    const request = new HTTPRequest();
    request.path = path;
    request.body = body || null;
    request.method = HTTPMethod.POST;

    const res = await this.send(request);
    const data = await res.blob();
    await this.downloadBlob(data, filename);
  }

  private async downloadBlob(data: Blob, filename: string) {
    const href = URL.createObjectURL(data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  async getBase64(url: string, query?: object): Promise<string | null> {
    const request = new HTTPRequest();
    request.path = url;
    request.query = query || null;
    request.method = HTTPMethod.GET;

    const res = await this.send(request);
    const data = await res.blob();

    const reader = new FileReader();
    await new Promise((resolve, reject) => {
      reader.onload = resolve;
      reader.onerror = reject;
      reader.readAsDataURL(data);
    });

    return reader.result?.toString() || null;
  }

  async printPdf(url: string, query?: object, filename?: string): Promise<void> {
    if (isSafari) {
      await this.download(url, filename || 'file.pdf', query);
      return;
    }

    const request = new HTTPRequest();
    request.path = url;
    request.query = query || null;
    request.method = HTTPMethod.GET;

    const res = await this.send(request);
    const data = await res.arrayBuffer();

    try {
      printPdf(data);
    } catch (err) {
      console.error(err);
      await this.download(url, filename || 'file.pdf', query);
    }
  }

  async postFormData<T>(
    path: string,
    form: FormData,
    onUploadProgress?: ({ loaded, total }: { loaded: number; total: number }) => void,
  ): Promise<T | null> {
    const request = new HTTPRequest();
    request.path = path;

    await waitOnline();
    const res = await axios
      .post<T>(request.getUrl(), form, {
        onUploadProgress: (progress) => {
          if (onUploadProgress) {
            onUploadProgress({
              loaded: progress.loaded,
              total: progress.total || 0,
            });
          }
        },
        withCredentials: true,
        headers: request.getHeaders(),
      })
      .then((res) => res.data)
      .catch((err) => showError(err?.response?.status || null));

    return res || null;
  }
}

export const http = new HTTP();
export const silenthttp = new HTTP(true);
