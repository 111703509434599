import { Manager, Socket } from 'socket.io-client';

import { config } from '@/util/config.ts';

// URL de WebSocket
const wsUrl = new URL('/', config.URL_API);
wsUrl.protocol = 'wss';

// Instance unique de Manager
export const socketManager = new Manager(wsUrl.href, {
  autoConnect: true,
  withCredentials: true,
});

type OnConnect = () => void;

const sockets: [Socket, OnConnect | undefined][] = [];

export const addSocket = (socket: Socket, onConnect?: OnConnect) => {
  sockets.push([socket, onConnect]);
};

window.addEventListener('offline', () => {
  sockets.map(([s]) => {
    s.disconnect();
  });
});

window.addEventListener('online', () => {
  sockets.map(([s, onConnect]) => {
    s.connect();
    if (typeof onConnect === 'function') {
      onConnect();
    }
  });
});
