import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { I18nNs } from '@/util/i18n';

import { usePopupLoader } from '../PopupLoaderContext/usePopupLoader';

type Props = {
  children: React.ReactElement;
};

export function OfflineProvider({ children }: Props): React.ReactElement {
  const [_isOnline, setIsOnline] = useState<boolean>(navigator.onLine);

  const loader = usePopupLoader();

  const { t } = useTranslation(I18nNs.Common);

  const isOnline = useRef<boolean>(_isOnline);
  isOnline.current = _isOnline;

  useEffect(() => {
    isOnline.current = _isOnline;
    if (isOnline.current) {
      loader(false, t('offline.message'));
      return;
    }

    const id = setTimeout(() => {
      loader(!isOnline.current, t('offline.message'));
    }, 3000);

    return () => {
      clearTimeout(id);
    };
  }, [_isOnline, loader, t]);

  useEffect(() => {
    const goOnline = () => setIsOnline(true);
    const goOffline = () => setIsOnline(false);

    window.addEventListener('online', goOnline);
    window.addEventListener('offline', goOffline);

    return () => {
      window.removeEventListener('online', goOnline);
      window.removeEventListener('offline', goOffline);
    };
  }, []);

  return children;
}
